import React, { useState, useEffect } from "react";
import "../scss/BlogPage7All.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import bof from './bof.png';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';


// const AccordionDropdown = () => {
//     const [activeIndexes, setActiveIndexes] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(false);
  
//     const handleClick = (index) => {
//       if (activeIndexes.includes(index)) {
//         setActiveIndexes(activeIndexes.filter((i) => i !== index));
//         setExpandedSection(false);
//       } else {
//         setActiveIndexes([...activeIndexes, index]);
//         setExpandedSection(true);
//       }
//     };

const BlogPage7All = () => {

    const data = useStaticQuery(graphql`
    query MyQueryAI {
        text1: allContentfulTextBlock(filter: { id: { eq: "874c82ce-6b31-58ea-8512-e4081971a14e" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text2: allContentfulTextBlock(filter: { id: { eq: "4288c2e9-8e0d-54fa-b795-870172c9424b" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text3: allContentfulTextBlock(filter: { id: { eq: "961ac669-c4e4-5631-9429-7f0831da828a" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text4: allContentfulTextBlock(filter: { id: { eq: "9d28e4b4-e18c-5053-9f25-b13b1f5978c2" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text5: allContentfulTextBlock(filter: { id: { eq: "87d87014-a0d6-5db2-8825-681090e01f3b" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text6: allContentfulTextBlock(filter: { id: { eq: "ebe43203-656a-5731-9bc0-91378ec14793" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text7: allContentfulTextBlock(filter: { id: { eq: "4ace7347-94f3-5deb-b061-083a53180d05" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text9: allContentfulTextBlock(filter: { id: { eq: "96c626b1-89ce-543f-b90c-3ed9d2c1137a" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text11: allContentfulTextBlock(filter: { id: { eq: "e778ee2e-9c40-504a-bc0f-842e55027961" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        pic1: allContentfulImageBlock(filter: { name: { eq: "Blog 7 Picture 1" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic2: allContentfulImageBlock(filter: { name: { eq: "Blog 7 Picture 2" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic3: allContentfulImageBlock(filter: { name: { eq: "Data Mining" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic4: allContentfulImageBlock(filter: { name: { eq: "blog 7 pic 4" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic6: allContentfulImageBlock(filter: { name: { eq: "latin" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }


    }
  `);


  const t1 = data.text1.edges[0].node;
  const t2 = data.text2.edges[0].node;
  const t3 = data.text3.edges[0].node;
  const t4 = data.text4.edges[0].node;
  const t5 = data.text5.edges[0].node;
  const t6 = data.text6.edges[0].node;
  const t7 = data.text7.edges[0].node;
  const t9 = data.text9.edges[0].node;
  const t11 = data.text11.edges[0].node;
  const p1 = data.pic1.edges[0].node;
  const p2 = data.pic2.edges[0].node;
  const p3 = data.pic3.edges[0].node;
  const p4 = data.pic4.edges[0].node;
  const p6 = data.pic6.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    const [activeSection, setActiveSection] = useState("");

    const sectionIds = [
      "What is Luxury Data Mining and Social Listening?",
      "What is Luxury Customer Sentiment Analysis?",
      "Using AI to Understand Luxury Customers",
      "Understand Brand Perception",
      "Acquire and Keep Customers"
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const sectionOffsets = sectionIds.map((sectionId) => {
          const sectionElement = document.getElementById(sectionId);
          return {
            id: sectionId,
            offsetTop: sectionElement.offsetTop,
            offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
          };
        });
    
        const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
        for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
          if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
            setActiveSection(id);
            break;
          }
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);







    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 500; // Adjust this value to determine when to show the contents
      const hideThreshold = 10000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleArrowClick = () => {
      // Scroll down 300 pixels when the arrow is clicked
      window.scrollBy({
        top: 400,
        behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
      });
    };


  return (
    <>
      <Helmet>
        <title> How AI is Helping Luxury Leaders Enhance Brand and Gain Customers</title>
        <meta name="description" content="Use Artificial Intelligence to Enhance Luxury Brand Perception and Awareness - Gain and Keep Customers and De-Risk and Protect Investments " />
        <meta name="keywords" content="examples for artifical intelligence in luxury, luxury data analysis methods" />
        <h1>Luxury Social Listening and Customer Sentiment Analysis</h1>
      </Helmet>
    <div className={`all1`}>
    <div className={`check1`}>



    {isContentsVisible && (
        <div>
          <div className="contentsListadsBlog">
            <p className="contentadsBlog">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                // href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}








      <div>
        <Helmet>
          <h2>Summary</h2>
        </Helmet>
        <div id="null" className="contentFeatureBlogPage3Text11AI">
          <div className="textContainerBlogPage3Text11AI">
            <p className="titleBlogPage3Text11AI">{t1.title}</p>
            {t1.bodyText && (
              <div className="bodyBlogPage3Text11AI">
                {documentToReactComponents(JSON.parse(t1.bodyText.raw))}
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div id="What is Luxury Digital Transformation?" className="contentFeatureBlogPage3Text22AI">
          <div className="textContainerBlogPage3Text22AI">
            {t2.bodyText && (
              <div className="bodyBlogPage3Text22AI">
                {documentToReactComponents(JSON.parse(t2.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog1AI'}>
              <img
                className={'arrows'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      {/* <div>
        <div className="contentFeatureQuoteBlogPage33AI">
          <div className="textContainerQuoteBlogPage33AI">
            <div className="openQuoteBlogPage33AI">“</div>
            <div className="quoteBodyBlogPage33AI">To power up e-commerce growth, the digital customer experience and behavioural insights are priorities for data and analytics according to fashion executives</div>
            <div className="closeQuoteBlogPage33AI">”</div>
            <img
              // data-aos="fade-up"
              className={"bofBlogPage33AI"}
              src={bof}
              alt="bof"
            />
          </div>
        </div>
      </div> */}



      <div>
        <Helmet>
          <h2>What is Luxury Data Mining and Social Listening?</h2>
        </Helmet>
        <div id="What is Luxury Data Mining and Social Listening?" className="contentFeatureBlogPage3Text33AI">
          <div className="textContainerBlogPage3Text33AI">
            <p className="titleBlogPage3Text33AI">{t3.title}</p>


              <div className={"imageContainerBlogPagePic33AI"}>
                <img
                  data-aos="fade-up"
                  className={"bigImage"}
                  alt="luxury data analysis methods"
                  key={""}
                  src={p3.image.image.file.url}
                />
              </div>



            {t3.bodyText && (
              <div className="bodyBlogPage3Text33AI">
                {documentToReactComponents(JSON.parse(t3.bodyText.raw))}
              </div>
            )}
          </div>
        </div>
      </div>



      {/* <div>
        <div className="contentFeatureBlogPagePic33AI">
          <div className={"imageContainerBlogPagePic33AI"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="china luxury market"
              key={""}
              src={p3.image.image.file.url}
            />
          </div>
        </div>
      </div> */}



      <div>
        <div className="contentFeatureBlogPage3Text44AI">
          <div className="textContainerBlogPage3Text44AI">
            {t4.bodyText && (
              <div className="bodyBlogPage3Text44AI">
                {documentToReactComponents(JSON.parse(t4.bodyText.raw))}
              </div>
            )}
            {/* <p className="titleBlogPage3Text44AI">{t4.title}</p> */}
          </div>
        </div>
      </div>



      {/* <div>
        <div className="contentFeatureBlogPagePic44AI">
          <div className={"imageContainerBlogPagePic44AI"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury digital transformation examples"
              key={""}
              src={p4.image.image.file.url}
            />
          </div>
        </div>
      </div> */}



      <div>
        <div id="Revlon - A Cautionary Tale" className="contentFeatureBlogPage3Text55AI">
          <div className="textContainerBlogPage3Text55AI">
          <p className="titleBlogPage3Text55AI">{t5.title}</p>
            {t5.bodyText && (
              <div className="bodyBlogPage3Text55AI">
                {documentToReactComponents(JSON.parse(t5.bodyText.raw))}
              </div>
            )}

          </div>
          <div className={'arrowContainerBlog2AI'}>
              <img
                className={'arrows'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>What is Luxury Customer Sentiment Analysis?</h2>
        </Helmet>
        <div id="What is Luxury Customer Sentiment Analysis?" className="contentFeatureBlogPage3Text66AI">
          <div className="textContainerBlogPage3Text66AI">
          <p className="titleBlogPage3Text66AI">{t6.title}</p>
            {t6.bodyText && (
              <div className="bodyBlogPage3Text66AI">
                {documentToReactComponents(JSON.parse(t6.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog3AI'}>
              <img
                className={'arrows'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>Using Artificial Intelligence to understand Luxury Customers</h2>
        </Helmet>
        <div id="Using AI to Understand Luxury Customers" className="contentFeatureBlogPage3Text77AI">
          <div className="textContainerBlogPage3Text77AI">
          <p className="titleBlogPage3Text77AI">{t7.title}</p>


          <div className={"imageContainerBlogPagePic44AI"}>
                <img
                  data-aos="fade-up"
                  className={"bigImage"}
                  alt="luxury customer behaviour analysis"
                  key={""}
                  src={p4.image.image.file.url}
                />
          </div>

            {t6.bodyText && (
              <div className="bodyBlogPage3Text77AI">
                {documentToReactComponents(JSON.parse(t7.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog4AI'}>
              <img
                className={'arrows'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>


      <Helmet>
          <h2>Understand Brand Perception (yours and competitors)</h2>
      </Helmet>
      <div>
        <div id="Understand Brand Perception" className="contentFeatureBlogPage3Text88AI">
          <div className="textContainerBlogPage3Text88AI">
          <p className="titleBlogPage3Text88AI">Understand Brand Perception (yours and competitors)</p>

          </div>
        </div>
      </div>




      <div>
        <div className="contentFeatureBlogPagePic11AI">
          <div className={"imageContainerBlogPagePic11AI"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="how to measure luxury brand awareness"
              key={""}
              src={p1.image.image.file.url}
            />
          </div>
        </div>
      </div>




      <div>
        <div id="hello" className="contentFeatureBlogPage3Text99AI">
          <div className="textContainerBlogPage3Text99AI">
            {t9.bodyText && (
              <div className="bodyBlogPage3Text99AI">
                {documentToReactComponents(JSON.parse(t9.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog5AI'}>
              <img
                className={'arrows'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>
















      <Helmet>
          <h2>Acquire and Keep Customers - Reverse Engineer Success</h2>
      </Helmet>
      <div>
        <div id="Acquire and Keep Customers" className="contentFeatureBlogPage3Text1010AI">
          <div className="textContainerBlogPage3Text1010AI">
          <p className="titleBlogPage3Text1010AI">Acquire and Keep Customers - Reverse Engineer Success</p>

          </div>
        </div>
      </div>




      <div>
        <div className="contentFeatureBlogPagePic22AI">
          <div className={"imageContainerBlogPagePic22AI"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="examples for artificial intelligence in luxury"
              key={""}
              src={p2.image.image.file.url}
            />
          </div>
        </div>
      </div>




      <div>
        <Helmet>
          <h2>Listen and Adapt to Customers Needs or Pay the Price</h2>
          <h3>Don’t Underestimate the Ecommerce Pure Play Startups</h3>
          <h3>Listen and React Fast to Customer Behaviour</h3>
        </Helmet>
        <div id="Summary" className="contentFeatureBlogPage3Text1111AI">
          <div className="textContainerBlogPage3Text1111AI">
            {t11.bodyText && (
              <div className="bodyBlogPage3Text1111AI">
                {documentToReactComponents(JSON.parse(t11.bodyText.raw))}
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerCEBlogLatinBlog">
          <div className={"imageContainerCEBlogLatinBlog"}>
            <img
              className={"bigImage"}
              key={""}
              src={p6.image.image.file.url}
            />
          </div>
        </div>
      </div>




      
    </div>
    {/* <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div> */}
{/*       
      <Footer /> */}
    </div>
    </>
  );
}

export default BlogPage7All;



