import * as React from "react"
import LayoutBlogDiversity from "../components/LayoutBlogDiversity"
import BlogPage7 from "../components/BlogPage/BlogPage7"
import BlogPage7All from "../components/BlogPage/BlogPage7All"
import BlogContents from '../components/BlogPage/BlogContents'
import ContactFormBlog from '../components/BlogPage/ContactFormBlog'
import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    
    <LayoutBlogDiversity>
      <Helmet>
        <meta property="og:title" content="How AI is Helping Luxury Leaders Enhance Brand and Gain Customers" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/6UA4fCLW23L7DD09dXiY9X/d1d96f428d4593ad55d7542c39c5d570/blog7.png" />
        <meta property="og:description" content="Use Artificial Intelligence to Enhance Luxury Brand Perception and Awareness - Gain and Keep Customers and De-Risk and Protect Investments" />
        <meta property="og:url" content="https://thefreshkid.com/examples-for-artificial-intelligence-in-luxury/" />
      </Helmet>
      <BlogPage7/>
      <BlogPage7All/>
      <ContactFormBlog/>
    </LayoutBlogDiversity>
  )
}

export default blog





